.Register {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 60px;
    margin-top: 20px;

    .Register_data {
        width: 48%;

        h1 {
            color: #fff;
        }

        .formContainer {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 80%;

            div {
                display: flex;
                flex-direction: column;

                label {
                    color: #fff;
                }

                input {
                    width: 65%;
                    background-color: #657DF6;
                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                    padding: 10px 20px;
                    border-radius: 6px;
                }

                ::placeholder {
                    color: rgba(255, 255, 255, 0.578)
                }

                small {
                    color: red;
                }
            }

            button {
                background: #F1216C;
                color: #fff;
                border: none;
                outline: none;
                font-size: 20px;
                border-radius: 6px;
                padding: 15px 20px;
                cursor: pointer;
                margin-top: 15px;
                width: 25%;

                &:hover {
                    background: #cc1d5c;
                }
            }

            .have {
                color: #fff;

                a {
                    color: #F1216C;
                    text-decoration: underline;
                }
            }

        }
    }

    .Register_logo {
        width: 40%;

        img {
            width: 100%;
        }
    }
}