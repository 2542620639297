@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,500;1,800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  outline: 0;
  border: 0;
  transition: 0.4s;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
  background-image: url("./imgs/background.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

}


img {
  display: block;
  width: 100%;
  object-fit: cover;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}